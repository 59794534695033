export const gateway = "https://western-bms-api.link.wawanesalife.com";
export const LoginUrlParameter = "https://stable-brokers.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=ndf0f18s11hj13bfnevl8gfuo&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fwestern-bms.link.wawanesalife.com%2Fredirect";
export const BrokerPoolClientParameter = "ndf0f18s11hj13bfnevl8gfuo";
export const BrokerPoolDomainParameter = "stable-brokers";
export const BrokerPoolParameter = "ca-central-1_Q5DSclAvd";

export const brokerBranding = {
	"western.link.wawanesalife.com": {
		a: {
			broker: "western",
			accessibilityLink: "https://westernfinancialgroup.ca/Accessibility-Guidelines",
			themeSwitcher: "hide",
			privacyLink: "https://westernfinancialgroup.ca/Privacy",
			scope: "western#branding#western.link.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "myinsurancenow@westernfg.ca",
			video_link: "dOPJxsVASJA",
			map2: "hide",
			environment_id: "stable",
			map1: "hide",
			brands: "western.link.wawanesalife.com",
			map4: "hide",
			map3: "hide",
			introduction: "dOPJxsVASJA",
			phone: "(888) 594-3105",
			menuPhone: "button",
			environment: "stable",
			website: "https://westernfinancialgroup.ca/",
			ftcLink: "https://westernfinancialgroup.ca/Customers-Fair-Treatment",
			layout: "flat",
			g4: "G-2QM64W4BM4",
			photo3: "hide",
			photo4: "hide",
			photo1: "hide",
			photo2: "hide",
			approve_user: "link_western",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "western.link.wawanesalife.com",
		},
		b: {
			broker: "western",
			accessibilityLink: "https://westernfinancialgroup.ca/Accessibility-Guidelines",
			themeSwitcher: "hide",
			privacyLink: "https://westernfinancialgroup.ca/Privacy",
			scope: "western#branding#western.link.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "myinsurancenow@westernfg.ca",
			video_link: "dOPJxsVASJA",
			map2: "hide",
			environment_id: "stable",
			map1: "hide",
			brands: "western.link.wawanesalife.com",
			map4: "hide",
			map3: "hide",
			introduction: "dOPJxsVASJA",
			phone: "(888) 594-3105",
			menuPhone: "button",
			environment: "stable",
			website: "https://westernfinancialgroup.ca/",
			ftcLink: "https://westernfinancialgroup.ca/Customers-Fair-Treatment",
			layout: "flat",
			g4: "G-2QM64W4BM4",
			photo3: "hide",
			photo4: "hide",
			photo1: "hide",
			photo2: "hide",
			approve_user: "link_western",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "western.link.wawanesalife.com",
		},
	},
};

//updated on Sun May 19 2024 13:26:13 GMT-0700 (Pacific Daylight Time)
